//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReconnectingWebSocket from 'reconnecting-websocket'
// import { getAuth, signInAnonymously } from 'firebase/auth'
// import { getMessaging, onMessage, getToken } from 'firebase/messaging'
import AppMenu from './components/AppMenu.vue'
import HeaderBar from './components/HeaderBar.vue'
import AppChangePartner from './components/AppChangePartner.vue'

import AppScrollToTopBtn from '~/components/AppScrollToTopBtn.vue'
import { makeNotificationSound } from '@/utils'

// import { appMessaging } from '@/plugins/firebase'

import AppConfirmModal from '@/plugins/confirmation/AppConfirmModal.vue'

const proccesNewOrderMsg = ({ orderId }, { toasted, $auth }) => {
  if (!$auth.user.scope.params?.notify_new_orders) return

  const msg = `Поступил новый заказ ${orderId}`
  toasted.info(msg, {
    position: 'top-right',
    duration: 4000,
  })
  makeNotificationSound()
}

const proccesSocketMsg = async (socketPayload, dependencies) => {
  const { payload, type } = socketPayload
  if (type === 'order_change') {
    const { systemOrdersRepository, $store, $route } = dependencies

    if (payload.state_ === 'new')
      proccesNewOrderMsg({ orderId: payload.order_id }, dependencies)

    if ($route.name !== 'orders') return
    const order = await systemOrdersRepository.show(payload.order_id)
    if (payload.state_ === 'new') {
      $store.commit(`orders/ADD_ITEM`, order.data)
    } else {
      $store.commit(`orders/UPDATE_ITEM`, order.data)
    }
  }
}
export default {
  components: {
    AppMenu,
    AppScrollToTopBtn,
    AppConfirmModal,
    AppChangePartner,
    HeaderBar,
  },
  provide() {
    return {
      toggleMenuProvide: this.toggleMenuWidth,
    }
  },
  data() {
    return {
      isMenuOpen: true,
      clipped: false,
      newOrderinterval: null,
      mobileMenu: [
        {
          icon: 'menu-home',
          to: { name: 'index' },
          text: 'Главная',
        },
        // {
        //   icon: 'menu-orders',
        //   to: { name: 'orders' },
        //   text: 'Заказы',
        // },
        {
          icon: 'notification',
          to: { name: 'inspire' },
          text: 'Уведомления',
        },

        {
          icon: 'menu-lines',
          to: { name: 'AppMobileMenuPage' },
          text: 'Меню',
        },
      ],
      socket: null,
    }
  },
  watch: {
    isMenuOpen(vall) {
      localStorage.setItem('MENU_STATE', vall)
    },
  },

  mounted() {
    // this.startWebSocket()

    this.startFirebasePushNotification()

    // this.socket.addEventListener('open', (e) => {
    //   console.log('open', e)
    // })

    // this.socket.addEventListener('error', (e) => {
    //   console.log('error', e)
    // })
  },
  beforeDestroy() {
    // this.socket.removeEventListener()
    // this.socket.close()
  },
  methods: {
    toggleMenuWidth(val) {
      if (val) {
        this.isMenuOpen = !this.isMenuOpen
      } else {
        this.isMenuOpen = !this.isMenuOpen
      }
    },
    async startFirebasePushNotification() {
      // await signInAnonymously(getAuth())
      // const token = await getToken(appMessaging, {
      //   vapidKey:
      //     'BBrv_vGnDs_QvRfepKHq6yb2NTG1iIXqr_JwmEhpCGSuWrgmIpIKiv94lOKi1Ns90ikv-DDmuTVGD1eoP-rkh4w',
      // })
      // if (token) {
      //   console.log(token)
      // } else {
      //   console.log('no token')
      // }
      // const messaging = getMessaging()
      // onMessage(messaging, (payload) => {
      //   console.log('mg on client', payload)
      // })
    },
    startWebSocket() {
      let athorizeCookie = this.$auth.strategy.token.get()

      if (athorizeCookie) athorizeCookie = athorizeCookie.replace('Bearer ', '')

      const formadtedBaseUrl = this.$config.baseURL.replace('https://', '')

      this.socket = new ReconnectingWebSocket(
        `wss://${formadtedBaseUrl}/system/orders/ws/?token=${athorizeCookie}`,
        null,
        { reconnectInterval: 3000, maxReconnectAttempts: 6 }
      )

      this.socket.addEventListener('message', (e) => {
        const prasedData = JSON.parse(e.data)
        proccesSocketMsg(prasedData, {
          toasted: this.$toasted,
          $store: this.$store,
          $route: this.$route,
          systemOrdersRepository: this.$systemOrdersRepository,
          $auth: this.$auth,
        })
      })

      this.socket.addEventListener('close', async (e) => {
        if (e.code === 1008 && e.reason === 'token_expired') {
          // this.socket.close()
          await this.$auth.refreshTokens()
          // this.startWebSocket()
        }
      })
    },
  },
}
