//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleSubtitle from './TitleSubtitle.vue'
import { lightenColor } from '~/utils'
export default {
  components: {
    TitleSubtitle,
  },
  props: {
    img: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    altImg: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  methods: { lightenColor },
}
